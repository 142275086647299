import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"
import Typical from "react-typical"
import symbol from '../images/PurromiSymbol.svg'
import twitter from '../images/twitter-icon.svg'

const IndexPage = () => (
  <Layout>
    <Seo title="Purromi" />
    <div className="MainContent">
    <Header />
    <div className="Hero">
      <div className="HeroGroup">
      
      <a target="_blank" href="https://blockmate.io">
      <h1>
        <span> 
        <Typical
        loop={Infinity}
        wrapper="span"
        steps={[
          'We are creators',
          3000,
          'We are designers',
          3000,
          'We are visionaries',
          3000,
          'We are Purromi',
          3000,
        ]}
        />
        </span>
        <br />
        Learn more about our
        latest product: Blockmate →
      </h1>
      </a>
      </div>

      <div className="BannerGroup">
      <a target="_blank" href="https://blockmate.io">
      <div className="Banner">
        <div className="BannerContent">
        <img src={symbol} width="30"/>
        <h6>Blockmate.io · We are connecting Fintech with Crypto →</h6>
        </div>
      </div>
      </a>
    </div>
    </div>
    
    
    <div className="FooterGroup">
    <div className="Footer">
      <h6>©{new Date().getFullYear()} Purromi j.s.a.   ·   info@purromi.com</h6>
      <img src={twitter} width="44"/>
    </div>
    </div>


    </div>
    {/* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
    <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link> <br />
      <Link to="/using-ssr">Go to "Using SSR"</Link> <br />
      <Link to="/using-dsg">Go to "Using DSG"</Link>
    </p> */}
  </Layout>
)

export default IndexPage
