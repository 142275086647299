import * as React from "react"
import PropTypes from "prop-types"
import logo from '../images/Purromi.svg';

import './Header.css'

const Header = ({ siteTitle }) => (
  <div className="Header">
      <div className="HeaderGroup"> 
      <img src={logo}/>
      <a href="mailto:info@purromi.com">Contact us</a>
      </div>
  </div>
  
  
  
  // <header
  //   style={{
  //     background: `rebeccapurple`,
  //     marginBottom: `1.45rem`,
  //   }}
  // >
  //   <div
  //     style={{
  //       margin: `0 auto`,
  //       maxWidth: 960,
  //       padding: `1.45rem 1.0875rem`,
  //     }}
  //   >
  //     <h1 style={{ margin: 0 }}>
  //       <Link
  //         to="/"
  //         style={{
  //           color: `white`,
  //           textDecoration: `none`,
  //         }}
  //       >
  //         {siteTitle}
  //       </Link>
  //     </h1>
  //   </div>
  // </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
